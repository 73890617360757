import * as THREE from 'three'
import React, { Suspense, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { ContactShadows } from '@react-three/drei'
import Model from './Model'
import Rig from './elements/Rig'

const Home = () => {
    return (
        <>
            <Canvas camera={{ position: [0, -10, 65], fov: 50 }} dpr={[1, 2]}>
                <pointLight position={[100, 100, 100]} intensity={0.8} />
                <hemisphereLight color="#ffffff" groundColor="#b9b9b9" position={[-7, 25, 13]} intensity={0.85} />
                <Suspense fallback={null}>
                    <group position={[0, 10, 0]}>
                        <Rig>
                            <Model url="/compressed.glb" />
                        </Rig>
                        <ContactShadows
                            rotation-x={Math.PI / 2}
                            position={[0, -35, 0]}
                            opacity={0.25}
                            width={100}
                            height={100}
                            blur={2}
                            far={50}
                        />
                    </group>
                </Suspense>
            </Canvas>
            <div class="overlay">
                <a href="#e">
                    <b>pmnd.rs</b>
                </a>
                <a class="right" href="#">
                    /csb
                </a>

                <h2>
                    There's not the smallest orb
                    <br />
                    which thou behold'st but in his motion
                    <br />
                    like an angel sings —
                </h2>
            </div>
        </>
    )
}

export default Home
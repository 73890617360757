import logo from './logo.svg';
import './App.css';

import Home from './Home'
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>


        <Route exact path='/' element={<Home />} />
        <Route exact path='home' element={<Home />} />

      </Routes>



    </BrowserRouter>
  );
}

export default App;
